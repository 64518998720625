.mpc-1-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 1920px;
  height: 2242px;
  object-fit: cover;
}
.mpc-1-child,
.mpc-1-iotampc {
  position: absolute;
  left: 190px;
}
.mpc-1-child {
  top: 78px;
  border-radius: var(--mpc-1-br-5xs);
  background-color: var(--mpc-1-dark-tanglepay);
  width: 148px;
  height: 40px;
}
.mpc-1-iotampc {
  top: 260px;
  font-size: var(--mpc-1-font5-size);
  line-height: 144px;
  font-weight: 800;
  text-align: left;
  display: flex;
  align-items: center;
  width: 588px;
  height: 112px;
}
.mpc-1-the-community-driven-secure,
.mpc-1-validator {
  font-weight: 600;
  display: flex;
  align-items: center;
}
.mpc-1-the-community-driven-secure {
  position: absolute;
  top: 392px;
  left: 199px;
  font-size: var(--mpc-1-font4-size);
  text-align: left;
  width: 575px;
  height: 68px;
}
.mpc-1-validator {
  position: relative;
  justify-content: center;
  width: 89px;
  height: 24px;
  flex-shrink: 0;
}
.mpc-1-validator-wrapper {
  position: absolute;
  top: 78px;
  left: 1329px;
  border-radius: var(--mpc-1-br-5xs);
  background-color: var(--mpc-1-dark-tanglepay);
  height: 40px;
  display: flex;
  flex-direction: row;
  padding: var(--mpc-1-padding-5xs) var(--mpc-1-padding-base);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--mpc-1-dark-t1);
}
.mpc-1-bridge {
  position: relative;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 63px;
  height: 24px;
  flex-shrink: 0;
}
.mpc-1-bridge-wrapper,
.mpc-1-iotampc1 {
  position: absolute;
  color: var(--mpc-1-dark-t1);
  display: flex;
}
.mpc-1-bridge-wrapper {
  top: 78px;
  left: 1467px;
  border-radius: var(--mpc-1-br-5xs);
  background-color: var(--mpc-1-dark-tanglepay);
  height: 40px;
  flex-direction: row;
  padding: var(--mpc-1-padding-5xs) var(--mpc-1-padding-base);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.mpc-1-iotampc1 {
  top: 86px;
  left: 206px;
  font-size: var(--mpc-1-font2-size);
  line-height: 32px;
  font-weight: 800;
  align-items: center;
  justify-content: center;
  width: 116px;
  height: 24px;
}
.mpc-1-vector-icon {
  position: relative;
  width: 32px;
  height: 25px;
  flex-shrink: 0;
}
.mpc-1-vector-wrapper {
  position: absolute;
  top: 78px;
  left: 1582px;
  border-radius: var(--mpc-1-br-5xs);
  background-color: var(--mpc-1-light-b5-day);
  width: 64px;
  height: 40px;
  display: flex;
  flex-direction: row;
  padding: var(--mpc-1-padding-5xs) var(--mpc-1-padding-base);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.mpc-1-vector-icon1 {
  position: relative;
  width: 32px;
  height: 24px;
  flex-shrink: 0;
}
.mpc-1-vector-container {
  position: absolute;
  top: 78px;
  left: 1666px;
  border-radius: var(--mpc-1-br-5xs);
  background-color: var(--mpc-1-light-b5-day);
  width: 64px;
  height: 40px;
  display: flex;
  flex-direction: row;
  padding: var(--mpc-1-padding-5xs) var(--mpc-1-padding-base);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.mpc-1-enter-child {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--mpc-1-br-base);
  border: 4px solid var(--mpc-1-dark-tanglepay);
  box-sizing: border-box;
}
.mpc-1-enter,
.mpc-1-enter-child,
.mpc-1-enter1 {
  position: absolute;
}
.mpc-1-enter1 {
  height: 80%;
  width: 93.75%;
  top: 10%;
  left: 3.57%;
  line-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mpc-1-enter {
  top: 523px;
  left: 586px;
  border-radius: var(--mpc-1-br-base);
  width: 188px;
  height: 52px;
  font-size: var(--mpc-1-font1-size);
  color: var(--mpc-1-dark-tanglepay);
}
.mpc-1-im-bridge {
  position: absolute;
  top: 516px;
  left: 196px;
  font-size: var(--mpc-1-font-size);
  line-height: 64px;
  font-weight: 800;
  text-align: left;
  display: flex;
  align-items: center;
  width: 345px;
  height: 64px;
}
.mpc-1-div {
  position: relative;
  width: 100%;
  height: 1280px;
  overflow: hidden;
  text-align: center;
  font-size: var(--mpc-1-font3-size);
  color: var(--mpc-1-dark-b1);
  font-family: var(--mpc-1-font);
}
