.mpc-1-icon {
  width: 100vw;
  height: 116.771vw;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.mpc-1-child, .mpc-1-iotampc {
  position: absolute;
  left: 9.89583vw;
}

.mpc-1-child {
  border-radius: var(--mpc-1-br-5xs);
  background-color: var(--mpc-1-dark-tanglepay);
  width: 7.70833vw;
  height: 2.08333vw;
  top: 4.0625vw;
}

.mpc-1-iotampc {
  font-size: var(--mpc-1-font5-size);
  text-align: left;
  width: 30.625vw;
  height: 5.83333vw;
  align-items: center;
  font-weight: 800;
  line-height: 7.5vw;
  display: flex;
  top: 13.5417vw;
}

.mpc-1-the-community-driven-secure, .mpc-1-validator {
  align-items: center;
  font-weight: 600;
  display: flex;
}

.mpc-1-the-community-driven-secure {
  font-size: var(--mpc-1-font4-size);
  text-align: left;
  width: 29.9479vw;
  height: 3.54167vw;
  position: absolute;
  top: 20.4167vw;
  left: 10.3646vw;
}

.mpc-1-validator {
  width: 4.63542vw;
  height: 1.25vw;
  flex-shrink: 0;
  justify-content: center;
  position: relative;
}

.mpc-1-validator-wrapper {
  border-radius: var(--mpc-1-br-5xs);
  background-color: var(--mpc-1-dark-tanglepay);
  height: 2.08333vw;
  padding: var(--mpc-1-padding-5xs) var(--mpc-1-padding-base);
  box-sizing: border-box;
  color: var(--mpc-1-dark-t1);
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 4.0625vw;
  left: 69.2188vw;
}

.mpc-1-bridge {
  width: 3.28125vw;
  height: 1.25vw;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  display: flex;
  position: relative;
}

.mpc-1-bridge-wrapper, .mpc-1-iotampc1 {
  color: var(--mpc-1-dark-t1);
  display: flex;
  position: absolute;
}

.mpc-1-bridge-wrapper {
  border-radius: var(--mpc-1-br-5xs);
  background-color: var(--mpc-1-dark-tanglepay);
  height: 2.08333vw;
  padding: var(--mpc-1-padding-5xs) var(--mpc-1-padding-base);
  box-sizing: border-box;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  top: 4.0625vw;
  left: 76.4063vw;
}

.mpc-1-iotampc1 {
  font-size: var(--mpc-1-font2-size);
  width: 6.04167vw;
  height: 1.25vw;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  line-height: 1.66667vw;
  top: 4.47917vw;
  left: 10.7292vw;
}

.mpc-1-vector-icon {
  width: 1.66667vw;
  height: 1.30208vw;
  flex-shrink: 0;
  position: relative;
}

.mpc-1-vector-wrapper {
  border-radius: var(--mpc-1-br-5xs);
  background-color: var(--mpc-1-light-b5-day);
  width: 3.33333vw;
  height: 2.08333vw;
  padding: var(--mpc-1-padding-5xs) var(--mpc-1-padding-base);
  box-sizing: border-box;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 4.0625vw;
  left: 82.3958vw;
}

.mpc-1-vector-icon1 {
  width: 1.66667vw;
  height: 1.25vw;
  flex-shrink: 0;
  position: relative;
}

.mpc-1-vector-container {
  border-radius: var(--mpc-1-br-5xs);
  background-color: var(--mpc-1-light-b5-day);
  width: 3.33333vw;
  height: 2.08333vw;
  padding: var(--mpc-1-padding-5xs) var(--mpc-1-padding-base);
  box-sizing: border-box;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 4.0625vw;
  left: 86.7708vw;
}

.mpc-1-enter-child {
  height: 100%;
  width: 100%;
  border-radius: var(--mpc-1-br-base);
  border: .20833vw solid var(--mpc-1-dark-tanglepay);
  box-sizing: border-box;
  inset: 0;
}

.mpc-1-enter, .mpc-1-enter-child, .mpc-1-enter1 {
  position: absolute;
}

.mpc-1-enter1 {
  height: 80%;
  width: 93.75%;
  justify-content: center;
  align-items: center;
  line-height: 2.08333vw;
  display: flex;
  top: 10%;
  left: 3.57%;
}

.mpc-1-enter {
  border-radius: var(--mpc-1-br-base);
  width: 9.79167vw;
  height: 2.70833vw;
  font-size: var(--mpc-1-font1-size);
  color: var(--mpc-1-dark-tanglepay);
  top: 27.2396vw;
  left: 30.5208vw;
}

.mpc-1-im-bridge {
  font-size: var(--mpc-1-font-size);
  text-align: left;
  width: 17.9688vw;
  height: 3.33333vw;
  align-items: center;
  font-weight: 800;
  line-height: 3.33333vw;
  display: flex;
  position: absolute;
  top: 26.875vw;
  left: 10.2083vw;
}

.mpc-1-div {
  width: 100%;
  height: 66.6667vw;
  text-align: center;
  font-size: var(--mpc-1-font3-size);
  color: var(--mpc-1-dark-b1);
  font-family: var(--mpc-1-font);
  position: relative;
  overflow: hidden;
}

/*# sourceMappingURL=mpc.40729a20.css.map */
